.bground-image {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-repeat: no-repeat;
}

.bground {
  overflow: auto;
  height: 100vh;
}

.ml-button {
  margin-right: 1rem;
  color: white;
}

.button {
  background-color: #35c749;
  height: '90px';
}

.button:hover {
  background-color: #09cf23ad;
}

.button-fixed-size {
  width: 150px;
  height: 50px;
}

@media (max-width: 768px) {
  .button-fixed-size {
    width: 79px;
    height: 30px;
  }
}

@media (min-width: 768px) {
  .button-fixed-size {
    width: 150px;
    height: 50px;
  }
}

@media (max-width: 320px) {
  .button-fixed-size {
    width: 100px;
    height: 50px;
  }
}
